import React, { useContext, useEffect, useRef, useState } from 'react';
import { NEWS_QUILL_TOOLBAR } from '../../../services/commonDataService';
import {
  BlogApiService,
  CategoryApiService,
  SubCategoryApiService
} from '../../../services/apiService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-hot-toast';
import UserContext from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

const AddContent = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [addBlogTitle, setAddBlogTitle] = useState('');
  const [addBlogDescription, setAddBlogDescription] = useState('');
  const [addBlogCategory, setAddBlogCategory] = useState('');
  const [addBlogSubCategory, setAddBlogSubCategory] = useState('');
  const [addBlogCoverImg, setAddBlogCoverImg] = useState(null);
  const [addBlogContent, setAddBlogContent] = useState('');
  const [addBlogKeywords, setAddBlogKeywords] = useState('');
  const [isHome, setIsHome] = useState('');
  const [isCaseStudy, setIsCaseStudy] = useState('');
  const getCategories = () => {
    CategoryApiService.getCategories()
      .then((response) => {
        if (response.data.success === true) {
          setCategories(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSubCategories = () => {
    SubCategoryApiService.getSubCategories()
      .then((response) => {
        if (response.data.success === true) {
          setSubCategories(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleAddBlog = () => {
    if (
      addBlogTitle === '' ||
      addBlogCategory === '' ||
      addBlogSubCategory === '' ||
      addBlogCoverImg === null ||
      addBlogContent === ''
    ) {
      toast.error('All fields are required!');
      return;
    }

    let formData = new FormData();
    formData.append('token', user?.token);
    formData.append('title', addBlogTitle);
    formData.append('description', addBlogDescription);
    formData.append('category', addBlogCategory);
    formData.append('subcategory', addBlogSubCategory);
    formData.append('coverImg', addBlogCoverImg);
    formData.append('content', addBlogContent);
    formData.append('keywords', addBlogKeywords);
    formData.append('isHome', isHome || false);
    formData.append('isCaseStudy', isCaseStudy || false);
    BlogApiService.addBlog(formData)
      .then((response) => {
        if (response.data.success === true) {
          toast.success('Blog added successfully!');
          setAddBlogTitle('');
          setAddBlogDescription('');
          setAddBlogCategory('');
          setAddBlogSubCategory('');
          setAddBlogCoverImg(null);
          setAddBlogContent('');
          setAddBlogKeywords('');
          navigate('/content');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCategories();
    getSubCategories();
  }, []);
  const onHomeChange = (e) => {
    setIsHome(e.target.checked);
    setIsCaseStudy(false);
  };

  const onCaseStudyChange = (e) => {
    setIsHome(false);
    setIsCaseStudy(e.target.checked);
  };
  function Back() {
    navigate('/content');
  }
  return (
    <div className='p-5'>
      <div className='flex items-center justify-between'>
        <h1 className='text-2xl'>Content</h1>
      </div>
      <div className='mt-5'>
        <div className='flex mx-5 justify-between'></div>
        <div className='flex flex-col gap-2 my-5'>
          <div className='flex flex-col gap-0.5'>
            <label
              htmlFor='title'
              className='text-sm font-medium text-gray-700'
            >
              Title
            </label>
            <input
              type='text'
              name='title'
              id='title'
              placeholder='Role of Sustainable Development...'
              required
              value={addBlogTitle}
              onChange={(e) => setAddBlogTitle(e.target.value)}
              className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
            />
          </div>
          <div className='flex flex-col gap-0.5'>
            <label
              htmlFor='description'
              className='text-sm font-medium text-gray-700'
            >
              Description
            </label>
            <input
              type='text'
              id='description'
              placeholder='The criticle role of Sustainable Development Goals in the post-pandemic world...'
              required
              value={addBlogDescription}
              onChange={(e) => setAddBlogDescription(e.target.value)}
              className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
            />
          </div>
          <div className='flex items-center gap-2'>
            <div className='flex w-1/2 flex-col gap-0.5'>
              <label
                htmlFor='category'
                className='text-sm font-medium text-gray-700'
              >
                Category
              </label>
              <select
                id='category'
                name='category'
                value={addBlogCategory}
                onChange={(e) => {
                  setAddBlogCategory(e.target.value);
                  setAddBlogSubCategory('');
                }}
                className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
              >
                <option value='' disabled>
                  Select Category
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className='flex w-1/2 flex-col gap-0.5'>
              <label
                htmlFor='subcategory'
                className='text-sm font-medium text-gray-700'
              >
                Sub category
              </label>
              <select
                id='subcategory'
                name='subcategory'
                value={addBlogSubCategory}
                onChange={(e) => setAddBlogSubCategory(e.target.value)}
                className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
              >
                <option value='' disabled>
                  Select Sub Category
                </option>
                {subCategories
                  .filter(
                    (sc) => sc.parentCategoryId === parseInt(addBlogCategory)
                  )
                  .map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {addBlogCategory == 8 && (
            <div className='flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700'>
              <input
                id='bordered-radio-1'
                required
                value={isHome}
                onChange={onHomeChange}
                checked={isHome === true}
                type='checkbox'
                name='bordered-radio'
                className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
              />
              <label
                htmlFor='bordered-radio-1'
                className='w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
              >
                Set To Home
              </label>
            </div>
          )}
          {addBlogCategory == 9 && (
            <div className='flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700'>
              <input
                id='bordered-radio-2'
                required
                value={isCaseStudy}
                onChange={onCaseStudyChange}
                checked={isCaseStudy === true}
                type='checkbox'
                name='bordered-radio'
                className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
              />
              <label
                htmlFor='bordered-radio-2'
                className='w-full/5 py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
              >
                Set To Home
              </label>
            </div>
          )}
          <div className='flex flex-col gap-0.5'>
            <label
              htmlFor='coverImg'
              className='text-sm font-medium text-gray-700'
            >
              Cover Image
            </label>
            <input
              type='file'
              name='coverImg'
              id='coverImg'
              required
              // value={addBlogCoverImg}
              onChange={(e) => setAddBlogCoverImg(e.target.files[0])}
              className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
            />
          </div>
          <div className='flex flex-col gap-0.5'>
            <label
              htmlFor='keywords'
              className='text-sm font-medium text-gray-700'
            >
              Keywords (comma separated)
            </label>
            <input
              type='text'
              name='keywords'
              placeholder='Sustainability, B2B, ...'
              id='keywords'
              required
              value={addBlogKeywords}
              onChange={(e) => setAddBlogKeywords(e.target.value)}
              className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
            />
          </div>
          <div className='flex flex-col gap-0.5'>
            <label
              htmlFor='coverImg'
              className='text-sm font-medium text-gray-700'
            >
              Content
            </label>
            <ReactQuill
              theme='snow'
              value={addBlogContent}
              onChange={setAddBlogContent}
              placeholder='Start writing here...'
              modules={{ toolbar: NEWS_QUILL_TOOLBAR }}
              className='block w-full border-2 border-gray-300 rounded-md shadow-sm focus-within:border-teal-500 focus:outline-none sm:text-sm'
            />
          </div>
        </div>
      </div>
      <div className='flex gap-2'>
        <button
          type='button'
          className='inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-teal-100 hover:bg-teal-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2'
          onClick={handleAddBlog}
        >
          Save
        </button>
        <button
          type='button'
          className='inline-flex justify-center rounded-md border border-transparent bg-teal-200 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2'
          onClick={Back}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default AddContent;
