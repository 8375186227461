import React, { useContext, useEffect, useState } from 'react';
import { Check, PencilLine, Plus, Trash2, X } from 'lucide-react';
import HeadlessUIModalComponent from '../../shared/HeadlessUIModal';
import ReactQuill from 'react-quill';
import { toast } from 'react-hot-toast';
import {
  BlogApiService,
  GlossaryApiService
} from '../../../services/apiService';
import UserContext from '../../../contexts/UserContext';
import { Switch } from '@headlessui/react';

const Glossaries = () => {
  const { user } = useContext(UserContext);

  const [showAddGlossary, setShowAddGlossary] = useState(false);
  const [showEditGlossary, setShowEditGlossary] = useState(false);
  const [showDeleteGlossary, setShowDeleteGlossary] = useState(false);

  const [blogList, setBlogList] = useState([]);
  const [glossaryList, setGlossaryList] = useState([]);
  const [glossaryToDelete, setGlossaryToDelete] = useState(null);

  const [addGlossaryTitle, setAddGlossaryTitle] = useState('');
  const [addGlossaryContent, setAddGlossaryContent] = useState('');
  const [addGlossaryRelatedBlogId, setAddGlossaryRelatedBlogId] =
    useState('none');
  const [addGlossarySourceName, setAddGlossarySourceName] = useState('');
  const [addGlossarySourceLink, setAddGlossarySourceLink] = useState('');

  const [editGlossaryId, setEditGlossaryId] = useState(null);
  const [editGlossaryTitle, setEditGlossaryTitle] = useState('');
  const [editGlossaryContent, setEditGlossaryContent] = useState('');
  const [editGlossaryRelatedBlogId, setEditGlossaryRelatedBlogId] =
    useState('none');
  const [editGlossarySourceName, setEditGlossarySourceName] = useState('');
  const [editGlossarySourceLink, setEditGlossarySourceLink] = useState('');

  const getBlogsList = async () => {
    BlogApiService.getBlogs({ token: user?.token })
      .then((res) => {
        if (res.data.success === true) {
          setBlogList(res.data.blogs);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGlossariesList = () => {
    GlossaryApiService.getGlossaries({ token: user?.token })
      .then((res) => {
        if (res.data.success === true) {
          setGlossaryList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBlogsList();
    getGlossariesList();
  }, []);

  const handleGlossaryLiveChange = (e, id) => {
    GlossaryApiService.changeGlossaryStatus({ token: user?.token, id, live: e })
      .then((res) => {
        if (res.data.success === true) {
          toast.success('Glossary status changed successfully.');
          getGlossariesList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const glossaryListJSX = (
    <div className='mt-6 flex flex-col'>
      <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='overflow-hidden border border-teal-50 md:rounded-lg'>
            <table className='min-w-full divide-y divide-teal-50'>
              <thead className='bg-teal-300'>
                <tr>
                  <th
                    scope='col'
                    className='px-4 py-3.5 text-left text-sm font-normal text-gray-700'
                  >
                    Glossary
                  </th>
                  <th
                    scope='col'
                    className='px-12 py-3.5 text-left text-sm font-normal text-gray-700'
                  >
                    Sources
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3.5 text-center text-sm font-normal text-gray-700'
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-teal-50 bg-teal-300/50'>
                {glossaryList.map((glossary) => (
                  <tr key={glossary.id}>
                    <td className='whitespace-nowrap px-4 py-4'>
                      <div className='text-sm text-gray-900'>
                        {glossary.title}
                      </div>
                      <div
                        className='text-sm text-gray-600'
                        dangerouslySetInnerHTML={{
                          __html:
                            glossary.content.substring(0, 50) +
                            (glossary.content.length > 50 ? '...' : '')
                        }}
                      />
                    </td>
                    <td className='whitespace-nowrap text-left px-12 py-4'>
                      <div className='text-sm text-gray-900'>
                        {glossary.sourceName || 'No sources'}
                      </div>
                    </td>
                    <td className='whitespace-nowrap flex items-center gap-2 text-center px-12 py-2'>
                      <div>
                        <Switch
                          checked={glossary.live}
                          onChange={(e) => {
                            handleGlossaryLiveChange(e, glossary.id);
                          }}
                          className={`bg-gray-400 text-white relative inline-flex h-[30px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                        >
                          <span
                            aria-hidden='true'
                            className={`${
                              glossary.live
                                ? 'translate-x-[2.125rem] bg-green-600'
                                : 'translate-x-0 bg-red-600'
                            }
                                                            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                          >
                            {glossary.live ? (
                              <Check className='w-4 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2' />
                            ) : (
                              <X className='w-4 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2' />
                            )}
                          </span>
                        </Switch>
                      </div>
                      <button
                        className='text-amber-900 bg-amber-200 hover:bg-amber-300 p-2 rounded-lg focus:outline-none'
                        onClick={() => {
                          setEditGlossaryId(glossary.id);
                          setEditGlossaryTitle(glossary.title);
                          setEditGlossaryContent(glossary.content);
                          setEditGlossaryRelatedBlogId(
                            glossary.relatedBlogId || 'none'
                          );
                          setEditGlossarySourceName(glossary.sourceName || '');
                          setEditGlossarySourceLink(glossary.sourceLink || '');
                          setShowEditGlossary(true);
                        }}
                      >
                        <PencilLine size={18} />
                      </button>

                      <button
                        className='text-red-900 bg-red-200 hover:bg-red-300 p-2 rounded-lg focus:outline-none'
                        onClick={() => {
                          setGlossaryToDelete(glossary.id);
                          setShowDeleteGlossary(true);
                        }}
                      >
                        <Trash2 size={18} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  const addGlossaryModalBodyJSX = (
    <div className='flex flex-col gap-4 my-5'>
      <div className='flex flex-col gap-2'>
        <label htmlFor='name' className='text-sm font-medium text-gray-700'>
          Title
        </label>
        <input
          type='text'
          name='name'
          id='name'
          required
          value={addGlossaryTitle}
          onChange={(e) => setAddGlossaryTitle(e.target.value)}
          className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
        />
      </div>
      <div className='flex flex-col gap-2'>
        <label htmlFor='name' className='text-sm font-medium text-gray-700'>
          Related Blog (Optional)
        </label>
        <select
          name='name'
          id='name'
          value={addGlossaryRelatedBlogId}
          onChange={(e) => setAddGlossaryRelatedBlogId(e.target.value)}
          className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
        >
          <option value='none'>None</option>
          {blogList.map((blog) => (
            <option key={blog.id} value={blog.id}>
              {blog.title}
            </option>
          ))}
        </select>
      </div>
      <div className='flex items-center gap-2'>
        <div className='flex w-1/2 flex-col gap-2'>
          <label htmlFor='name' className='text-sm font-medium text-gray-700'>
            Source Name (Optional)
          </label>
          <input
            type='text'
            name='name'
            id='name'
            value={addGlossarySourceName}
            onChange={(e) => setAddGlossarySourceName(e.target.value)}
            className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
          />
        </div>
        <div className='flex w-1/2 flex-col gap-2'>
          <label htmlFor='name' className='text-sm font-medium text-gray-700'>
            Source URL (Optional)
          </label>
          <input
            type='url'
            name='name'
            id='name'
            value={addGlossarySourceLink}
            onChange={(e) => setAddGlossarySourceLink(e.target.value)}
            className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
          />
        </div>
      </div>
      <div className='flex w-full flex-col gap-2'>
        <label htmlFor='name' className='text-sm font-medium text-gray-700'>
          Content
        </label>
        <ReactQuill
          type='text'
          name='name'
          id='name'
          required
          value={addGlossaryContent}
          onChange={setAddGlossaryContent}
          className='block w-full border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus-within:border-teal-500 focus:outline-none sm:text-sm'
        />
      </div>
    </div>
  );

  const editGlossaryModalBodyJSX = (
    <div className='flex flex-col gap-4 my-5'>
      <div className='flex flex-col gap-2'>
        <label htmlFor='name' className='text-sm font-medium text-gray-700'>
          Title
        </label>
        <input
          type='text'
          name='name'
          id='name'
          required
          value={editGlossaryTitle}
          onChange={(e) => setEditGlossaryTitle(e.target.value)}
          className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
        />
      </div>
      <div className='flex flex-col gap-2'>
        <label htmlFor='name' className='text-sm font-medium text-gray-700'>
          Related Blog (Optional)
        </label>
        <select
          name='name'
          id='name'
          value={editGlossaryRelatedBlogId}
          onChange={(e) => setEditGlossaryRelatedBlogId(e.target.value)}
          className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
        >
          <option value='none'>None</option>
          {blogList.map((blog) => (
            <option key={blog.id} value={blog.id}>
              {blog.title}
            </option>
          ))}
        </select>
      </div>
      <div className='flex items-center gap-2'>
        <div className='flex w-1/2 flex-col gap-2'>
          <label htmlFor='name' className='text-sm font-medium text-gray-700'>
            Source Name (Optional)
          </label>
          <input
            type='text'
            name='name'
            id='name'
            value={editGlossarySourceName}
            onChange={(e) => setEditGlossarySourceName(e.target.value)}
            className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
          />
        </div>
        <div className='flex w-1/2 flex-col gap-2'>
          <label htmlFor='name' className='text-sm font-medium text-gray-700'>
            Source URL (Optional)
          </label>
          <input
            type='url'
            name='name'
            id='name'
            value={editGlossarySourceLink}
            onChange={(e) => setEditGlossarySourceLink(e.target.value)}
            className='block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm'
          />
        </div>
      </div>
      <div className='flex w-full flex-col gap-2'>
        <label htmlFor='name' className='text-sm font-medium text-gray-700'>
          Content
        </label>
        <ReactQuill
          type='text'
          name='name'
          id='name'
          required
          value={editGlossaryContent}
          onChange={setEditGlossaryContent}
          className='block w-full border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus-within:border-teal-500 focus:outline-none sm:text-sm'
        />
      </div>
    </div>
  );

  const handleAddGlossary = () => {
    if (!addGlossaryTitle || !addGlossaryContent) {
      toast.error('Title and Content are required.!');
      return;
    }

    let body = {
      title: addGlossaryTitle,
      content: addGlossaryContent
    };
    if (addGlossaryRelatedBlogId !== 'none') {
      body.relatedBlogId = parseInt(addGlossaryRelatedBlogId);
    }
    if (addGlossarySourceName) {
      body.sourceName = addGlossarySourceName;
    }
    if (addGlossarySourceLink) {
      body.sourceLink = addGlossarySourceLink;
    }

    GlossaryApiService.addGlossary({ token: user?.token, ...body })
      .then((res) => {
        if (res.data.success === true) {
          toast.success('Glossary added successfully.');
          setShowAddGlossary(false);
          setAddGlossaryTitle('');
          setAddGlossaryContent('');
          setAddGlossaryRelatedBlogId('none');
          setAddGlossarySourceName('');
          setAddGlossarySourceLink('');
          getGlossariesList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditGlossary = () => {
    if (!editGlossaryTitle || !editGlossaryContent) {
      toast.error('Title and Content are required.!');
      return;
    }

    let body = {
      id: editGlossaryId,
      title: editGlossaryTitle,
      content: editGlossaryContent
    };
    if (editGlossaryRelatedBlogId !== 'none') {
      body.relatedBlogId = parseInt(editGlossaryRelatedBlogId);
    }
    if (editGlossarySourceName) {
      body.sourceName = editGlossarySourceName;
    }
    if (editGlossarySourceLink) {
      body.sourceLink = editGlossarySourceLink;
    }

    GlossaryApiService.updateGlossary({ token: user?.token, ...body })
      .then((res) => {
        if (res.data.success === true) {
          toast.success('Glossary updated successfully.');
          setShowEditGlossary(false);
          setEditGlossaryId(null);
          setEditGlossaryTitle('');
          setEditGlossaryContent('');
          setEditGlossaryRelatedBlogId('none');
          setEditGlossarySourceName('');
          setEditGlossarySourceLink('');
          getGlossariesList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteGlossary = () => {
    GlossaryApiService.deleteGlossary({
      token: user?.token,
      id: glossaryToDelete
    })
      .then((res) => {
        if (res.data.success === true) {
          toast.success('Glossary deleted successfully.');
          setShowDeleteGlossary(false);
          setGlossaryToDelete(null);
          getGlossariesList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='p-5'>
      {/* Add */}
      <HeadlessUIModalComponent
        displayState={showAddGlossary}
        setDisplayState={setShowAddGlossary}
        headingChildren='Add New Glossary'
        bodyChildren={addGlossaryModalBodyJSX}
        footerChildren={
          <div className='flex gap-2'>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-teal-100 hover:bg-teal-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2'
              onClick={handleAddGlossary}
            >
              Add Glossary
            </button>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-teal-200 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2'
              onClick={() => {
                setShowAddGlossary(false);
              }}
            >
              Cancel
            </button>
          </div>
        }
        maxWidthClass='max-w-4xl'
      />

      {/* Edit */}
      <HeadlessUIModalComponent
        displayState={showEditGlossary}
        setDisplayState={setShowEditGlossary}
        headingChildren='Edit Glossary'
        bodyChildren={editGlossaryModalBodyJSX}
        footerChildren={
          <div className='flex gap-2'>
            <button
              onClick={handleEditGlossary}
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-teal-100 hover:bg-teal-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2'
            >
              Edit Glossary
            </button>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-teal-200 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2'
              onClick={() => {
                setShowEditGlossary(false);
              }}
            >
              Cancel
            </button>
          </div>
        }
        maxWidthClass='max-w-4xl'
      />

      {/* Delete */}
      <HeadlessUIModalComponent
        displayState={showDeleteGlossary}
        setDisplayState={setShowDeleteGlossary}
        headingChildren='Delete Glossary'
        bodyChildren='Are you sure you want to delete this glossary?'
        footerChildren={
          <div className='flex gap-2'>
            <button
              onClick={handleDeleteGlossary}
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-100 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
            >
              Delete Glossary
            </button>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-red-200 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
              onClick={() => {
                setShowDeleteGlossary(false);
              }}
            >
              Cancel
            </button>
          </div>
        }
        maxWidthClass='max-w-md'
      />

      <div className='flex items-center justify-between'>
        <h1 className='text-2xl'>Glossaries</h1>
        <button
          onClick={() => setShowAddGlossary(true)}
          className='bg-teal-200 flex items-center gap-1 font-medium text-[15px] py-2 px-4 rounded-lg hover:bg-teal-300 text-teal-900 focus:outline-none'
        >
          <Plus className='h-5 w-5' /> Add New Glossary
        </button>
      </div>

      <div className='mt-5 w-10/12 mx-auto'>{glossaryListJSX}</div>
    </div>
  );
};

export default Glossaries;
