import './App.css';
import { useContext } from 'react';
import UserContext from './contexts/UserContext';
import Login from './components/pages/login/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Sidebar from './components/shared/Sidebar';
import UserInfo from './components/shared/UserInfo';

import Dashboard from './components/pages/dashboard/Dashboard';
import BrandLogos from './components/pages/brand-logo/BrandLogos';
import Categories from './components/pages/categories/Categories';
import Content from './components/pages/content/Content';
import AddContent from './components/pages/content/AddContent';
import EditContent from './components/pages/content/EditContent';
import Glossaries from './components/pages/glossaries/Glossaries';
import Users from './components/pages/users/Users';
import DemoBookings from './components/pages/demo-bookings/DemoBookings';

function App() {
  const { user } = useContext(UserContext);
  console.log('user:', user);

  return (
    <>
      {/* A component that only fetches userinfo */}
      <UserInfo />
      <Toaster
        toastOptions={{
          style: {
            background: '#17255499',
            color: '#fff',
            backdropFilter: 'blur(3px)'
          }
        }}
      />
      <BrowserRouter>
        {user === null ? (
          <Login />
        ) : (
          <main className='flex'>
            <Sidebar />
            <main className='min-h-screen w-[calc(100vw-224px)]'>
              <Routes>
                <Route path='/' exact element={<Dashboard />} />
                <Route path='/brand-logos' exact element={<BrandLogos />} />
                <Route path='/categories' exact element={<Categories />} />
                <Route path='/content' exact element={<Content />} />
                <Route path='/add-content' exact element={<AddContent />} />
                <Route
                  path='/edit-content/:id'
                  exact
                  element={<EditContent />}
                />
                <Route path='/glossaries' exact element={<Glossaries />} />
                {user?.roleId === 1 && (
                  <Route path='/users' exact element={<Users />} />
                )}
                <Route path='/demo-bookings' exact element={<DemoBookings />} />
              </Routes>
            </main>
          </main>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
