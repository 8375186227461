import axios from 'axios';
import { API_BASE_URL } from './commonDataService';

export const UserApiService = {
  route: 'user',

  addAdmin(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/addAdmin`, body);
  },

  deleteAdmin(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/deleteAdmin`, body);
  },

  adminLogin(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/adminLogin`, body);
  },

  getUserInfo(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/getUserInfo`, body);
  },

  getAdminList(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/getAdminList`, body);
  }
};

export const CategoryApiService = {
  route: 'category',

  addCategory(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/addCategory`, body);
  },
  getCategories() {
    return axios.get(`${API_BASE_URL}/${this.route}/getCategories`);
  },
  deleteCategory(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/deleteCategory`, body);
  }
};

export const SubCategoryApiService = {
  route: 'subCategory',

  addSubCategory(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/addSubCategory`, body);
  },
  getSubCategories() {
    return axios.get(`${API_BASE_URL}/${this.route}/getSubCategories`);
  },
  deleteSubCategory(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/deleteSubCategory`, body);
  }
};

export const BlogApiService = {
  route: 'blog',

  addBlog(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/addBlog`, body);
  },
  getBlogs(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/getBlogsAdmin`, body);
  },
  updateBlog(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/updateBlog`, body);
  },
  changeBlogStatus(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/changeBlogStatus`, body);
  },
  deleteBlog(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/deleteBlog`, body);
  },
  changeBlogOrder(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/changeBlogOrder`, body);
  },
  getBlogById(id) {
    return axios.get(`${API_BASE_URL}/${this.route}/getBlogByPk/` + id);
  }
};

export const GlossaryApiService = {
  route: 'glossary',

  addGlossary(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/addGlossary`, body);
  },
  getGlossaries(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/getGlossaries`, body);
  },
  updateGlossary(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/updateGlossary`, body);
  },
  changeGlossaryStatus(body) {
    return axios.post(
      `${API_BASE_URL}/${this.route}/changeGlossaryStatus`,
      body
    );
  },
  deleteGlossary(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/deleteGlossary`, body);
  }
};

export const BrandLogoApiService = {
  route: 'brandLogo',

  addBrandLogo(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/addBrandLogo`, body);
  },
  getBrandLogos() {
    return axios.get(`${API_BASE_URL}/${this.route}/getBrandLogos`);
  },
  deleteBrandLogo(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/deleteBrandLogo`, body);
  }
};

export const DemoBookingApiService = {
  route: 'demoBooking',

  getDemoBookings(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/getDemoBookings`, body);
  },
  deleteDemoBooking(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/deleteDemoBooking`, body);
  }
};

export const DashboardApiService = {
  route: 'dashboard',

  getDashboardData() {
    return axios.get(`${API_BASE_URL}/${this.route}/getDashboardData`);
  }
};
