export const NEWS_QUILL_TOOLBAR = [
  [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
  [{ align: [] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['link', 'image', 'video'],
  ['clean']
];

export const API_BASE_URL = 'https://cms-api.bharatcarbon.earth/api/v1';

export const LOGOS_BASE_URL = `${API_BASE_URL}/brandLogos/`;
export const COVER_IMAGE_BASE_URL = `${API_BASE_URL}/coverImages/`;
